/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, {useEffect, useRef, useState} from "react";
import DoctorSidebar from "../sidebar";
import Header from "../../header";
import { doctordashboardclient01, doctordashboardclient02, doctordashboardprofile01, doctordashboardprofile02, doctordashboardprofile04, doctordashboardprofile05, doctordashboardprofile3 } from "../../imagepath";
import Chart from 'react-apexcharts';
import DoctorFooter from "../../common/doctorFooter";
import { Link, useHistory } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import api from '../../../api';
import dayjs from "dayjs";
import {RxDrugDosage, RxDrugForm, RxDrugName} from "../../common/drug";
import {Tag, Card, Button, Modal, Descriptions, Divider, Flex, Space, Collapse, List, Avatar, Typography, Row, Col, Form, Input} from "antd";
import {PaperClipOutlined, UserOutlined, SendOutlined, CheckCircleOutlined} from '@ant-design/icons';
import {DashboardSidebar} from "../../patients/dashboard/sidebar/sidebar";
const { confirm } = Modal;
var isToday = require("dayjs/plugin/isToday");
// import isToday from 'dayjs/plugin/isToday' // ES 2015

dayjs.extend(isToday);

const SupportConversation = (props) => {
  const history = useHistory();
  const authUser = useAuthUser();
  const [supportConversation, setSupportConversation] = useState({});
  const [supportConversationMessages, setSupportConversationMessages] = useState([]);
  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState(false);

  useEffect(() => {
    fetchSupportConversation(props.match.params.conversationId);
    setClientReady(true);
  }, []);

  const fetchSupportConversation = (id) => {
    api.get('/support_conversations/' + id + '.json')
        .then((res) => {
          if (res.data) {
            setSupportConversation(res.data)
            console.log(res.data)
          }
        })
        .catch(err => console.log(err));
  }

  const onFinish = (values) => {
    api.post('/support_messages.json', {
      support_message: {
        support_conversation_id: supportConversation.id,
        user_id: authUser.id,
        content: values.content
      }
    })
      .then(async (res) => {
        if (res.data) {
          form.resetFields();
          setSupportConversation({...supportConversation, support_messages: [...supportConversation.support_messages, res.data]})
          console.log(res.data)
        }
      })
      .catch(err => console.log(err));
  }

  const summaryConversationItems = [
    {
      key: '1',
      label: 'Subject',
      children: supportConversation.topic,
    },
    {
      key: '2',
      label: 'Created At',
      children: dayjs(supportConversation.created_at).format('MMM D, YYYY h:mm A'),
    },
  ];

  const messagesList = supportConversation.support_messages;

  return (
    <div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Customer Support</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Customer Support
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/*<div className="col-lg-4 col-xl-3 theiaStickySidebar">*/}
            {/*  <div className="stickybar">*/}
            {/*    /!* Profile Sidebar *!/*/}
            {/*    {authUser.pharmacy ?*/}
            {/*        <DoctorSidebar/> : (*/}
            {/*            authUser.patient ?*/}
            {/*                <DashboardSidebar/> : null*/}
            {/*        )*/}
            {/*    }*/}
            {/*    /!* /Profile Sidebar *!/*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="col-lg-12 col-xl-12">
              <div>
                <Card title={
                  <div className="d-flex justify-content-space-between">
                    <Flex style={{width: '100%'}} justify='space-between' align='center'>
                      <Link to={authUser.pharmacy ? "/accounts/pharmacies/dashboard" : (authUser.patient ? "/accounts/patients/dashboard" : "/accounts/staffs/dashboard")}>
                        <i className="fa-solid fa-arrow-left-long" /> Back To Dashboard
                      </Link>
                      }
                      <div>
                        {`SUP-REF#${10000+supportConversation.id}`}
                      </div>
                    </Flex>
                  </div>
                  }>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={24} md={16}>
                      <Typography.Title level={5}>Messages:</Typography.Title>
                      <Space style={{width: '100%'}} direction="vertical">
                        <List
                            dataSource={messagesList}
                            bordered={true}
                            style={{padding: 10, height: 400, overflowY: 'auto'}}
                            renderItem={(item) => (
                                item.user_id === supportConversation.user_id ?
                                    <List.Item key={item.email} style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, flexDirection:  authUser.id === item.user_id ? 'row-reverse' : 'row'}}>
                                      <Avatar icon={<UserOutlined/>} style={{flexShrink: 0, backgroundColor: authUser.id === item.user_id ? '#87d068' : '#f56a00'}}/>
                                      <Space direction={"vertical"} style={{textAlign: authUser.id === item.user_id ? "right" : 'left', gap: 0}}>
                                        <Typography.Text style={{fontWeight: 'bold'}}>{
                                          authUser.id === item.user_id ?
                                              'You' :
                                              `${item.user.role === 'pharmacy' ? 'Pharmacy' : 'Patient'}`
                                        }
                                        </Typography.Text>
                                        <Typography.Text level={5}>{item.content}</Typography.Text>
                                      </Space>
                                    </List.Item>
                                      :
                                    <List.Item key={item.email} style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, flexDirection:  authUser.id === item.user_id ? 'row-reverse' : 'row'}}>
                                      <Avatar icon={<UserOutlined/>} style={{flexShrink: 0, backgroundColor: authUser.id === item.user_id ? '#87d068' : '#f56a00'}}/>
                                      <Space direction={"vertical"} style={{textAlign: authUser.id === item.user_id ? "right" : 'left', gap: 0}}>
                                        <Typography.Text style={{fontWeight: 'bold'}}>{
                                          authUser.id === item.user_id ?
                                              'You' :
                                              'Customer Support'
                                        }
                                        </Typography.Text>
                                        <Typography.Text level={5}>{item.content}</Typography.Text>
                                      </Space>
                                    </List.Item>
                            )}
                        />
                        <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
                          <Space.Compact style={{ width: '100%' }} className={'align-items-center'}>
                            <Form.Item
                                style={{flex: '1 1 auto'}}
                                       name="content"
                                       rules={[{ required: true, message: 'Please input message text.' }]}
                            >
                              <Input.TextArea rows={3} placeholder="Type and send your message here." />
                            </Form.Item>
                            <Form.Item shouldUpdate>
                              {() => (
                                  <Button
                                      type="link"
                                      size={"large"}
                                      htmlType="submit"
                                      icon={<SendOutlined />}
                                      disabled={
                                        !clientReady ||
                                        !form.isFieldsTouched(true) ||
                                        !!form.getFieldsError().filter(({ errors }) => errors.length).length
                                      }
                                  >
                                  </Button>
                              )}
                            </Form.Item>
                          </Space.Compact>
                        </Form>
                      </Space>
                    </Col>
                    <Col span={24} md={8}>
                      <Typography.Title level={5}>Contact Admin Details:</Typography.Title>
                      <Space style={{width: '100%', marginBottom: 10}} direction="vertical">
                        <Collapse
                            collapsible="header"
                            items={
                                  [
                                    {
                                      key: '1',
                                      label: 'Info',
                                      children: <Descriptions column={2} colon={false} title="" layout="vertical" items={summaryConversationItems} />,
                                    },
                                  ]}
                        />
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Content */}
    </div>
  );
};

export default SupportConversation;
