import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {Button, Form, Input, Space, Table, Tag, Typography} from "antd";
import api from '../../../api';
import dayjs from "dayjs";

const SupportDashboard = (props) => {
  const authUser = useAuthUser();
  const history = useHistory();
  const [supportConversations, setSupportConversations] = useState([]);

  useEffect(() => {
    fetchSupportConversations();
  }, []);

  const fetchSupportConversations = () => {
    api.get('/support_conversations.json')
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            const data = res.data.filter((conv) => conv.user_id === authUser.id || authUser.role === 'staff')
                .map((conv) => ({
                  ...conv,
                  user_email: conv.user.email,
                  user_role: conv.user.role
                }))
            setSupportConversations(data)
          }
          // setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }

  const columns = [
    {
      title: 'Support ID#',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Subject',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: 'User Email',
      dataIndex: 'user_email',
      key: 'user_email',
    },
    {
      title: 'User Role',
      dataIndex: 'user_role',
      key: 'user_role',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (data) => dayjs(data.created_at).format('MM-DD-YYYY')
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (data) => <Button type="link" onClick={() => history.push(`/accounts/users/support-conversations/${data.id}`)} >View Messages</Button>,
    },
  ];

  return (
    <>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Support Dashboard</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Support Dashboard
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Contact Us */}
      <section className="contact-section">
        <div className="container">
          <div className="row">
            {/*<div className="col-lg-4 col-xl-3 theiaStickySidebar">*/}
            {/*  /!* Profile Sidebar *!/*/}
            {/*  {authUser.pharmacy ?*/}
            {/*      <DoctorSidebar/> : (*/}
            {/*          authUser.patient ?*/}
            {/*              <SupportDashboardSidebar/> : null*/}
            {/*      )*/}
            {/*  }*/}
            {/*  /!* /Profile Sidebar *!/*/}
            {/*</div>*/}
            <div className="col-lg-12 col-xl-12">
              <div className="card w-100">
                <div className="card-body">
                  <Table columns={columns} dataSource={supportConversations} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Contact Us */}
    </>
  );
};

export default SupportDashboard;
