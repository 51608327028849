/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, {useEffect, useRef, useState} from "react";
import DoctorSidebar from "../sidebar";
import Header from "../../header";
import { doctordashboardclient01, doctordashboardclient02, doctordashboardprofile01, doctordashboardprofile02, doctordashboardprofile04, doctordashboardprofile05, doctordashboardprofile3 } from "../../imagepath";
import Chart from 'react-apexcharts';
import DoctorFooter from "../../common/doctorFooter";
import { Link, useHistory } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import api from '../../../api';
import dayjs from "dayjs";
import {RxDrugDosage, RxDrugForm, RxDrugName} from "../../common/drug";
import {
  Alert,
  Card,
  Button,
  Modal,
  Descriptions,
  Divider,
  Flex,
  Space,
  Collapse,
  List,
  Avatar,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Select
} from "antd";
import {
  PaperClipOutlined,
  UserOutlined,
  SendOutlined,
  CheckCircleOutlined,
  CheckOutlined
} from '@ant-design/icons';

const { confirm } = Modal;
var isToday = require("dayjs/plugin/isToday");
// import isToday from 'dayjs/plugin/isToday' // ES 2015

dayjs.extend(isToday);

const RxTransaction = (props) => {
  const history = useHistory();
  const authUser = useAuthUser();
  const [rxTransaction, setRxTransaction] = useState({});
  const [form] = Form.useForm();
  const [clientReady, setClientReady] = useState(false);
  const [form1] = Form.useForm();
  const [client1Ready, setClient1Ready] = useState(false);

  useEffect(() => {
    fetchTransaction(props.match.params.transactionId);
    setClientReady(true);
    setClient1Ready(true);
  }, []);

  const fetchTransaction = (id) => {
    api.get('/rx_search_transactions/' + id + '.json')
        .then((res) => {
          if (res.data) {
            setRxTransaction(res.data)
            console.log(res.data)
          }
        })
        .catch(err => console.log(err));
  }

  const onFinish = (values) => {
    const patient_or_pharmacy = authUser.pharmacy ? {pharmacy_id: rxTransaction.rx_search_order.pharmacy_id} : {patient_id: rxTransaction.rx_search_request.patient_id}
    api.post('/rx_transaction_messages.json', {
      rx_transaction_message: {
        rx_search_transaction_id: rxTransaction.id,
        content: values.content,
        ...patient_or_pharmacy
      }
    })
        .then(async (res) => {
          if (res.data) {
            form.resetFields();
            setRxTransaction({...rxTransaction, rx_transaction_messages: [...rxTransaction.rx_transaction_messages, res.data]})
            console.log(res.data)
          }
        })
        .catch(err => console.log(err));
  }

  const completeRxTransaction = (transaction_id, delivery_values = {}) => {
    api.put(`/rx_search_transactions/${transaction_id}.json`, {
      status: 2,
      ...delivery_values,
      delivery_carrier_service:
          (delivery_values.delivery_carrier_service === "Other" ? delivery_values.other_carrier_service : delivery_values.delivery_carrier_service )
    })
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            setRxTransaction(res.data)
          }
        })
        .catch(err => console.log(err));
  }

  const summaryDrugItems = [
    {
      key: '1',
      label: 'Drug Name',
      children: rxTransaction.rx_drug ? <RxDrugName rxcui={rxTransaction.rx_drug?.rxcui} /> : "",
    },
    {
      key: '2',
      label: 'Dosage',
      children: rxTransaction.rx_drug && <RxDrugDosage rxcui={rxTransaction.rx_drug?.rxcui} />,
    },
    {
      key: '3',
      label: 'Form',
      children: rxTransaction.rx_drug && <RxDrugForm rxcui={rxTransaction.rx_drug?.rxcui} />,
    },
    {
      key: '4',
      label: 'Quantity',
      children: rxTransaction.rx_search_request?.drug_quantity,
    }
  ];

  const summaryYourInfoItems = [
    {
      key: '1',
      label: 'First Name',
      children: rxTransaction.patient?.first_name,
    },
    {
      key: '2',
      label: 'Last Name',
      children: rxTransaction.patient?.last_name,
    },
    {
      key: '3',
      label: 'Birth Date',
      children: dayjs(rxTransaction.patient?.birthdate).format('MMMM DD, YYYY'),
    },
    {
      key: '4',
      label: 'Email',
      children: rxTransaction.patient?.user?.email,
    },
    {
      key: '5',
      label: 'Phone',
      children: rxTransaction.patient?.phone,
    },
    {
      key: '6',
      label: 'Address',
      children: [rxTransaction.patient?.address?.street, rxTransaction.patient?.address?.city, rxTransaction.patient?.address?.state, rxTransaction.patient?.address?.zip_code].join(' '),
    },
  ];

  const summaryDoctorItems = [
    {
      key: '1',
      label: 'Name of Prescriber',
      children: rxTransaction.rx_search_request?.prescriber_name,
    },
    {
      key: '2',
      label: 'Prescriber\'s Phone',
      children: rxTransaction.rx_search_request?.prescriber_phone,
    },
  ];

  const summaryPharmacyItems = [
    {
      key: '1',
      label: 'Name of Pharmacy',
      children: rxTransaction.rx_search_request?.rx_pharmacy_name,
    },
    {
      key: '2',
      label: 'Pharmacy\'s Phone',
      children: rxTransaction.rx_search_request?.rx_pharmacy_phone,
    },
    {
      key: '3',
      label: 'Address',
      children: [rxTransaction.rx_pharmacy_address?.street, rxTransaction.rx_pharmacy_address?.city, rxTransaction.rx_pharmacy_address?.state, rxTransaction.rx_pharmacy_address?.zip_code].join(' '),
    },
  ];

  const summaryUploadsItems = [
    {
      key: '1',
      label: 'Insurance',
      children: rxTransaction.rx_search_request?.insurance_image ? <Button type="link" href={rxTransaction.rx_search_request?.insurance_image} target="_blank"><PaperClipOutlined /></Button> : "N/A",
    },
    {
      key: '2',
      label: 'Coupon',
      children: rxTransaction.rx_search_request?.coupon_image ? <Button type="link" href={rxTransaction.rx_search_request?.coupon_image} target="_blank"><PaperClipOutlined /></Button> : "N/A",
    },
  ];


  const summaryPharmacyInfoItems = [
    {
      key: '1',
      label: 'Name',
      children: rxTransaction.pharmacy?.name,
      span: 2
    },
    {
      key: '2',
      label: 'First Name',
      children: rxTransaction.pharmacy?.first_name,
    },
    {
      key: '3',
      label: 'Last Name',
      children: rxTransaction.pharmacy?.last_name,
    },
    {
      key: '5',
      label: 'Phone Number',
      children: rxTransaction.pharmacy?.phone,
    },
    {
      key: '6',
      label: 'Address',
      children: [rxTransaction.pharmacy?.address?.street, rxTransaction.pharmacy?.address?.city, rxTransaction.pharmacy?.address?.state, rxTransaction.pharmacy?.address?.zip_code].join(' '),
    },
  ];

  const messagesList = rxTransaction.rx_transaction_messages;

  return (
    <div className="col-lg-8 col-xl-9">
      <div>
        <Card title={
          <div className="d-flex justify-content-space-between">
            <Flex style={{width: '100%'}} justify='space-between' align='center'>
              {authUser.pharmacy &&
              <Link to={"/accounts/pharmacies/rx-transactions"}>
                <i className="fa-solid fa-arrow-left-long" /> All Transactions
              </Link>
              }
              {authUser.patient &&
              <Link to={"/accounts/patients/dashboard"}>
                <i className="fa-solid fa-arrow-left-long" /> Back To Dashboard
              </Link>
              }
              <div>
                {
                  authUser.pharmacy && rxTransaction.status === 'shipping' && rxTransaction.shipping === 'pickup' &&
                  <Button type="default"
                          onClick={() => {
                            confirm({
                              title: "Complete Transaction!",
                              icon: <CheckCircleOutlined />,
                              content: "Are you sure you want to complete the transaction?",
                              okText: "Yes, Complete",
                              okType: 'primary',
                              cancelText: 'Cancel',
                              onOk() {
                                completeRxTransaction(rxTransaction.id)
                              },
                            });
                          }}>Complete Transaction</Button>
                }
                {
                  authUser.pharmacy && rxTransaction.status === 'shipping' && rxTransaction.shipping === 'delivery' &&
                  <Form form={form1} layout="inline" onFinish={(values) => completeRxTransaction(rxTransaction.id, values)}>
                    <Form.Item
                        name="delivery_carrier_service"
                        rules={[{ required: true, message: 'Please input carrier service.' }]}
                    >
                      <Select
                          style={{ width: '150px' }}
                          placeholder="Select Carrier Service"
                          options={[
                            {label: "UPS", value: 'UPS'},
                            {label: "FedEX", value: 'FedEX'},
                            {label: "USPS", value: 'USPS'},
                            {label: "Other Service", value: 'Other'},
                          ]}
                      />
                    </Form.Item>
                    <Form.Item
                       shouldUpdate={(prevValues, curValues) => prevValues.delivery_carrier_service !== curValues.delivery_carrier_service}
                       noStyle
                    >
                      {({ getFieldValue }) =>
                          getFieldValue('delivery_carrier_service') === 'Other' ? (
                              <Form.Item name="other_carrier_service" rules={[{ required: true }]}>
                                <Input placeholder="Name Other Service"/>
                              </Form.Item>
                          ) : null
                      }
                    </Form.Item>
                    <Form.Item
                        name="delivery_tracking_number"
                        rules={[{ required: true, message: 'Please input tracking number.' }]}
                    >
                      <Input placeholder="Tracking Number"/>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                      {() => (
                          <Button
                              icon={<CheckOutlined/>}
                              type="primary"
                              htmlType="submit"
                              // disabled={
                              //   !client1Ready ||
                              //   !form1.isFieldsTouched(true) ||
                              //   !!form1.getFieldsError().filter(({ errors }) => errors.length).length
                              // }
                          >
                            Complete
                          </Button>
                      )}
                    </Form.Item>
                  </Form>
                }
                {
                  authUser.pharmacy && rxTransaction.status === 'completed' &&
                  <Alert message="COMPLETED" type="success" showIcon/>
                }
                {
                  authUser.pharmacy && rxTransaction.status === 'initiated' &&
                  <Alert message="AWAITING PATIENT" type="info" showIcon/>
                }

                {/* for Patients */}
                {
                  authUser.patient && rxTransaction.status === 'initiated' &&
                  <Alert message="Accepted" type="warning" showIcon/>
                }
                {
                  authUser.patient && rxTransaction.status === 'shipping' &&
                  <Alert message="Processing" type="info" showIcon/>
                }
                {
                  authUser.patient && rxTransaction.status === 'completed' &&
                  (rxTransaction.shipping === "pickup" ? <Alert message="Ready for Pick up" type="success" showIcon/> : <Alert message="Shipped" type="success" showIcon/>)
                }
              </div>
            </Flex>
          </div>
          }>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24} md={16}>
              <Typography.Title level={5}>Messages:</Typography.Title>
              <Space style={{width: '100%'}} direction="vertical">
                <List
                    dataSource={messagesList}
                    bordered={true}
                    style={{padding: 10, height: 400, overflowY: 'auto'}}
                    renderItem={(item) => (
                          authUser.pharmacy ?
                            <List.Item key={item.email} style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, flexDirection:  item.pharmacy_id ? 'row-reverse' : 'row'}}>
                              <Avatar icon={<UserOutlined/>} style={{flexShrink: 0, backgroundColor: item.pharmacy_id ? '#87d068' : '#f56a00'}}/>
                              <Space direction={"vertical"} style={{textAlign: item.pharmacy_id ? "right" : 'left', gap: 0}}>
                                <Typography.Text style={{fontWeight: 'bold'}}>{
                                  item.pharmacy_id ?
                                      'You' :
                                      `${rxTransaction.patient.first_name} ${rxTransaction.patient.last_name}`}
                                </Typography.Text>
                                <Typography.Text level={5}>{item.content}</Typography.Text>
                              </Space>
                            </List.Item>
                              :
                            <List.Item key={item.email} style={{justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, flexDirection:  item.patient_id ? 'row-reverse' : 'row'}}>
                              <Avatar icon={<UserOutlined/>} style={{flexShrink: 0, backgroundColor: item.patient_id ? '#87d068' : '#f56a00'}}/>
                              <Space direction={"vertical"} style={{textAlign: item.patient_id ? "right" : 'left', gap: 0}}>
                                <Typography.Text style={{fontWeight: 'bold'}}>{
                                  item.patient_id ?
                                      'You' :
                                      <>{`${rxTransaction.pharmacy.first_name} ${rxTransaction.pharmacy.last_name}`}
                                        <span className={'text-black-50'}>{` (${rxTransaction.pharmacy.name})`}</span>
                                      </>
                                }
                                </Typography.Text>
                                <Typography.Text level={5}>{item.content}</Typography.Text>
                              </Space>
                            </List.Item>
                    )}
                />
                <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
                  <Space.Compact style={{ width: '100%' }} className={'align-items-center'}>
                    <Form.Item
                        style={{flex: '1 1 auto'}}
                               name="content"
                               rules={[{ required: true, message: 'Please input message text.' }]}
                    >
                      <Input.TextArea rows={3} placeholder="Type and send your message here." />
                    </Form.Item>
                    <Form.Item shouldUpdate>
                      {() => (
                          <Button
                              type="link"
                              size={"large"}
                              htmlType="submit"
                              icon={<SendOutlined />}
                              disabled={
                                !clientReady ||
                                !form.isFieldsTouched(true) ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                              }
                          >
                          </Button>
                      )}
                    </Form.Item>
                  </Space.Compact>
                </Form>
              </Space>
            </Col>
            <Col span={24} md={8}>
              <Typography.Title level={5}>Order Details: ({`REF#${10000+rxTransaction.rx_search_order?.id}`})</Typography.Title>
              <Space style={{width: '100%', marginBottom: 10}} direction="vertical">
                <Collapse
                    collapsible="header"
                    items={
                      authUser.role === 'pharmacy' ?
                          [
                              {
                                key: '1',
                                label: 'Medication',
                                children: <Descriptions column={2} colon={false} title="" layout="vertical" items={summaryDrugItems} />,
                              },
                              {
                                key: '2',
                                label: 'Patient Info',
                                children: <Descriptions column={2} colon={false} title="" layout="vertical" items={summaryYourInfoItems} />,
                              },
                              {
                                key: '3',
                                label: "Doctor",
                                children: <Descriptions column={2} colon={false} title="" layout="vertical" items={summaryDoctorItems} />,
                              },
                              {
                                key: '4',
                                label: "Pharmacy",
                                children: <Descriptions column={2} colon={false} title="" layout="vertical" items={summaryPharmacyItems} />,
                              },
                              {
                                key: '5',
                                label: "Uploads",
                                children: <Descriptions column={2} colon={false} title="" layout="vertical" items={summaryUploadsItems} />,
                              },
                          ] :
                          [
                            {
                              key: '1',
                              label: 'Medication',
                              children: <Descriptions column={2} colon={false} title="" layout="vertical" items={summaryDrugItems} />,
                            },
                            {
                              key: '2',
                              label: 'Pharmacy Info',
                              children: <Descriptions column={2} colon={false} title="" layout="vertical" items={summaryPharmacyInfoItems} />,
                            },
                          ]}
                />
              </Space>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default RxTransaction;
