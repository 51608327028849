import React, {useEffect, useState} from "react";
import {Form, Input, Button, Typography, Alert} from 'antd';
import {LoadingOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import loginBanner from "../../app/assets/images/login-banner.png";
import { Link, useHistory } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import api from '../../api';

const CreatePassword = (props) => {
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(null);
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    setSaving(true);
    api.put('/password.json', {user: {...values, id: props.match.params.user, reset_password_token: props.match.params.token}})
        .then((res)=>{
          if(res.status === 200) {
          }
          setSaving(false);
          setErrors("Reset password succeeded. Please login with new password.");
        })
        .catch((error) => {
          setErrors("Reset password failed. Password length needs to be more than 6 characters.");
          setSaving(false);
        })
  }

  useEffect(() => {
    document.body.classList.add("account-page");

    return () => document.body.classList.remove("account-page");
  }, []);

  return (
    <>
      <Header {...props} />
      <>
        {/* Page Content */}
        <div className="content top-space">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {/* Account Content */}
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    {/*<div className="col-md-7 col-lg-6 login-left">*/}
                    {/*  <img*/}
                    {/*    src={loginBanner}*/}
                    {/*    className="img-fluid"*/}
                    {/*    alt="Login Banner"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h3>Create Password</h3>
                        <p className="small text-muted">
                          Enter your password and confirm.
                        </p>
                      </div>
                      {/* Forgot Password Form */}
                      <Form
                          form={form}
                          name="forgot_password"
                          onFinish={onSubmit}
                      >
                        {errors &&
                        <Alert message={errors} type="success" style={{marginBottom: 20, fontWeight: 'bold', textAlign: "left"}} />
                        }

                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: 'Please input your Password!' }]}
                            hasFeedback
                        >
                          <Input.Password
                              size={"large"}
                              prefix={<LockOutlined className="site-form-item-icon" />}
                              type="password"
                              placeholder="Password"
                          />
                        </Form.Item>

                        <Form.Item
                            name="password_confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm your password!',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                              }),
                            ]}
                        >
                          <Input.Password
                              size={"large"}
                              prefix={<LockOutlined className="site-form-item-icon" />}
                              type="password_confirm"
                              placeholder="Reenter password"
                          />
                        </Form.Item>

                        <Form.Item>
                          <Button
                              size={"large"}
                              type="primary" htmlType="submit" className="w-100 login-btn"
                              icon={saving ? <LoadingOutlined /> : null}>
                            Save Password
                          </Button>
                          <hr/>
                          <Button
                              size={"large"}
                              type="default" htmlType="submit" className="w-100 login-btn"
                              onClick={() => history.push('/login')}
                              disabled={saving}>
                            Login
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
                {/* /Account Content */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </>

      <Footer {...props} />
    </>
  );
};

export default CreatePassword;
