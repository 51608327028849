import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import loginBanner from "../../../app/assets/images/login-banner.png";
import Header from "../../header";
import Footer from "../../footer";
import {Form, Input, Button, Alert, DatePicker, Select} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import axios from "axios";

const PharmacyRegister = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const signIn = useSignIn();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onSubmit = (values) => {
    setLoading(true);
    axios.post('/users/signup.json', {user: {...values, role: "pharmacy", other_states: values.other_states.join(',')}})
        .then((res)=>{
          console.log(res);
          if(res.status === 200){
            history.push('/pharmacy-register-success');
          }
          setLoading(false);
        })
        .catch((error) => {
          setErrors("Email address is already been taken.");
          setLoading(false);
          console.log(error)
        })
  }

  useEffect(() => {
    document.body.classList.add("account-page");

    return () => document.body.classList.remove("account-page");
  }, []);

  return (
      <>
        <Header {...props} />

        <>
          {/* Breadcrumb */}
          <div className="breadcrumb-bar-two">
            <div className="container">
              <div className="row align-items-center inner-banner">
                <div className="col-md-12 col-12 text-center">
                  <h2 className="breadcrumb-title">Pharmacist Register</h2>
                  {/*<nav aria-label="breadcrumb" className="page-breadcrumb">*/}
                  {/*  <ol className="breadcrumb">*/}
                  {/*    <li className="breadcrumb-item">*/}
                  {/*      <Link to="/">Home</Link>*/}
                  {/*    </li>*/}
                  {/*    <li className="breadcrumb-item" aria-current="page">*/}
                  {/*      Dashboard*/}
                  {/*    </li>*/}
                  {/*  </ol>*/}
                  {/*</nav>*/}
                </div>
              </div>
            </div>
          </div>
          {/* /Breadcrumb */}
          {/* Page Content */}
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  {/* Register Content */}
                  <div className="account-content">
                    <div className="row align-items-center justify-content-center">
                      {/*<div className="col-md-7 col-lg-6 login-left">*/}
                      {/*  <img*/}
                      {/*    src={loginBanner}*/}
                      {/*    className="img-fluid"*/}
                      {/*    alt="Doccure Register"*/}
                      {/*  />*/}
                      {/*</div>*/}
                      <div className="col-md-12 col-lg-6 login-right">
                        <div className="login-header">
                          <h3>
                            Pharmacist Register{" "}
                            <Link to="/register">
                              Not a Pharmacist?
                            </Link>
                          </h3>
                        </div>
                        {/* Register Form */}
                        <Form
                            layout={'vertical'}
                            label="vertical"
                            form={form}
                            name="register"
                            onFinish={onSubmit}
                            style={{ maxWidth: 600 }}
                        >
                          {/*<div className="form-group form-focus">*/}
                          {/*  <Form.Item name="first_name" rules={[{ required: true, message: "First name is required" }]}*/}
                          {/*  >*/}
                          {/*    <Input*/}
                          {/*        type="text"*/}
                          {/*        className="form-control"*/}
                          {/*        placeholder="First name"*/}
                          {/*    />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          {/*<div className="form-group form-focus">*/}
                          {/*  <Form.Item name="last_name" rules={[{ required: true, message: "Last name is required" }]}*/}
                          {/*  >*/}
                          {/*    <Input*/}
                          {/*        type="text"*/}
                          {/*        className="form-control"*/}
                          {/*        placeholder="Last name"*/}
                          {/*    />*/}
                          {/*  </Form.Item>*/}
                          {/*</div>*/}
                          <div className="form-group">
                            <Form.Item name="email"
                                       label="Email address"
                                       rules={[{ required: true, message: "Email address is required" }]}
                            >
                              <Input
                                  size="large"
                                  type="email"
                                  placeholder="Email address"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="password"
                                       label="Password"
                                       rules={[{ required: true, message: "Password is required" }]}
                            >
                              <Input
                                  size="large"
                                  type="password"
                                  placeholder="Password"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="name"
                                       label="Pharmacy name"
                                       rules={[{ required: true, message: "Pharmacy name is required" }]}
                            >
                              <Input
                                  size="large"
                                  placeholder="Pharmacy name"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="first_name"
                                       label="First name"
                                       rules={[{ required: true, message: "First name is required" }]}
                            >
                              <Input
                                  size="large"
                                  placeholder="First name"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="last_name"
                                       label="Last name"
                                       rules={[{ required: true, message: "Last name is required" }]}
                            >
                              <Input
                                  size="large"
                                  placeholder="Last name"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="phone"
                                       label="Phone number"
                                       rules={[{ required: true, message: "Phone number is required" }]}
                            >
                              <Input
                                  size="large"
                                  placeholder="Phone number"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="other_states"
                                       label="Other states"
                                       rules={[]}
                            >
                              <Select
                                  mode="multiple"
                                  size="large"
                                  style={{ width: '100%' }}
                                  placeholder="Please select states supported"
                                  options={[['All States', 'All'],
                                    ['Alabama', 'AL'],
                                    ['Alaska', 'AK'],
                                    ['Arizona', 'AZ'],
                                    ['Arkansas', 'AR'],
                                    ['California', 'CA'],
                                    ['Colorado', 'CO'],
                                    ['Connecticut', 'CT'],
                                    ['Delaware', 'DE'],
                                    ['District of Columbia', 'DC'],
                                    ['Florida', 'FL'],
                                    ['Georgia', 'GA'],
                                    ['Hawaii', 'HI'],
                                    ['Idaho', 'ID'],
                                    ['Illinois', 'IL'],
                                    ['Indiana', 'IN'],
                                    ['Iowa', 'IA'],
                                    ['Kansas', 'KS'],
                                    ['Kentucky', 'KY'],
                                    ['Louisiana', 'LA'],
                                    ['Maine', 'ME'],
                                    ['Maryland', 'MD'],
                                    ['Massachusetts', 'MA'],
                                    ['Michigan', 'MI'],
                                    ['Minnesota', 'MN'],
                                    ['Mississippi', 'MS'],
                                    ['Missouri', 'MO'],
                                    ['Montana', 'MT'],
                                    ['Nebraska', 'NE'],
                                    ['Nevada', 'NV'],
                                    ['New Hampshire', 'NH'],
                                    ['New Jersey', 'NJ'],
                                    ['New Mexico', 'NM'],
                                    ['New York', 'NY'],
                                    ['North Carolina', 'NC'],
                                    ['North Dakota', 'ND'],
                                    ['Ohio', 'OH'],
                                    ['Oklahoma', 'OK'],
                                    ['Oregon', 'OR'],
                                    ['Pennsylvania', 'PA'],
                                    ['Puerto Rico', 'PR'],
                                    ['Rhode Island', 'RI'],
                                    ['South Carolina', 'SC'],
                                    ['South Dakota', 'SD'],
                                    ['Tennessee', 'TN'],
                                    ['Texas', 'TX'],
                                    ['Utah', 'UT'],
                                    ['Vermont', 'VT'],
                                    ['Virginia', 'VA'],
                                    ['Washington', 'WA'],
                                    ['West Virginia', 'WV'],
                                    ['Wisconsin', 'WI'],
                                    ['Wyoming', 'WY']].map(state => ({label: state[0], value: state[1]}))}
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="npi_number"
                                       label="NPI number"
                                       rules={[{ required: true, message: "NPI number is required" }]}
                            >
                              <Input
                                  size="large"
                                  placeholder="NPI number"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="dea_number"
                                       label="DEA number"
                                       rules={[{ required: true, message: "DEA number is required" }]}
                            >
                              <Input
                                  size="large"
                                  placeholder="DEA number"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="license_number"
                                       label="Pharmacy license number"
                                       rules={[{ required: true, message: "Pharmacy license number is required" }]}
                            >
                              <Input
                                  size="large"
                                  placeholder="Pharmacy license number"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="license_issuer_name"
                                       label="License issuer name"
                                       rules={[{ required: true, message: "License issuer name is required" }]}
                            >
                              <Input
                                  size="large"
                                  placeholder="License issuer name"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="license_issue_date"
                                       label="License issue date"
                                       rules={[{ required: true, message: "License issue date is required" }]}
                            >
                              <DatePicker
                                  format={'MMMM DD, YYYY'}
                                  style={{ width: '100%' }}
                                  size="large"
                                  placeholder="License issue date"
                              />
                            </Form.Item>
                          </div>
                          <div className="form-group">
                            <Form.Item name="license_expiration_date"
                                       label="License expiration date"
                                       rules={[{ required: true, message: "License expiration date is required" }]}
                            >
                              <DatePicker
                                  format={'MMMM DD, YYYY'}
                                  style={{ width: '100%' }}
                                  size="large"
                                  placeholder="License expiration date"
                              />
                            </Form.Item>
                          </div>
                          <div className="text-end">
                            <Link
                                className="forgot-link"
                                to="/login"
                            >
                              Already have an account?
                            </Link>
                          </div>
                          {errors &&
                          <Alert
                              message="Error"
                              description={errors}
                              type="error"
                              showIcon
                              className="mb-3"
                          />
                          }
                          <Button
                              size={"large"}
                              type="primary" htmlType="submit" className="w-100 login-btn"
                              icon={loading ? <LoadingOutlined /> : null}>
                            Signup
                          </Button>
                        </Form>
                        {/* /Register Form */}
                      </div>
                    </div>
                  </div>
                  {/* /Register Content */}
                </div>
              </div>
            </div>
          </div>
          {/* /Page Content */}
        </>

        <Footer {...props} />
      </>
  );
};

export default PharmacyRegister;
