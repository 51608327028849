import React, { useState } from "react";
import {Alert, Button, Form, Input} from "antd";
import {LoadingOutlined, LockOutlined} from "@ant-design/icons";
import api from "../../../api";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Password = (props) => {
  const authUser = useAuthUser();
  const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    setSaving(true);
    api.put('/users/' + authUser.id + '/save_password.json', {user: {...values, id: authUser.id}})
        .then((res)=>{
          if(res.status === 200) {
          }
          setSaving(false);
          setSuccess(true);
          setErrors("Saving new password succeeded!");
        })
        .catch((error) => {
          setSuccess(false);
          setErrors("Changing password failed. Please make sure you have entered correct old password. Also new password length must be more than 6 characters.");
          setSaving(false);
        })
  }

  return (
    <div className="col-lg-8 col-xl-9">
      <div className="dashboard-header">
        <h3>Change Password</h3>
      </div>
      <div className="col-md-12 col-lg-6">
        <div className="login-header">
          <p className="small text-muted">
            Enter your old & new passwords and confirm.
          </p>
        </div>
        {/* Forgot Password Form */}
        <Form
            layout={'vertical'}
            form={form}
            name="forgot_password"
            onFinish={onSubmit}
        >
          {errors &&
          <Alert message={errors} type={success ? "success" : "error"} style={{marginBottom: 20, fontWeight: 'bold', textAlign: "left"}} />
          }

          <Form.Item
              name="old_password"
              label="Old Password"
              rules={[{ required: true, message: 'Please input your old Password!' }]}
          >
            <Input.Password
                size={"large"}
                type="password"
                placeholder="Old Password"
            />
          </Form.Item>

          <Form.Item
              label="New Password"
              name="password"
              rules={[{ required: true, message: 'Please input your new Password!' }]}
          >
            <Input.Password
                size={"large"}
                type="password"
                placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
              label="Confirm Password"
              name="password_confirm"
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The new password that you entered do not match!'));
                  },
                }),
              ]}
          >
            <Input.Password
                size={"large"}
                type="password_confirm"
                placeholder="Confirm password"
            />
          </Form.Item>

          <Form.Item>
            <Button
                size={"large"}
                type="primary" htmlType="submit" className="w-100 login-btn"
                icon={saving ? <LoadingOutlined /> : null}>
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Password;
