import React, {useEffect, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios'
import {AutoComplete, Select, ConfigProvider, Form, Input, Button, Alert} from "antd";
import {LoadingOutlined, LockOutlined, UserOutlined} from '@ant-design/icons';
import useSignIn from 'react-auth-kit/hooks/useSignIn';

import loginBanner from "../../app/assets/images/login-banner.png";
import Header from "../header";
import Footer from "../footer";

const LoginContainer = (props) => {
  const history = useHistory();
  const signIn = useSignIn();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onSubmit = (values) => {
    setLoading(true);
    setErrors(null);
    axios.post('/users/login.json', {user: values})
        .then((res)=>{
          console.log(res);
          if(res.status === 200 || res.status === 201){
            if(signIn({
              auth: {
                token: res.headers.authorization,
                type: 'Bearer'
              },
              refresh: null,
              userState: res.data
            }))
            { // Only if you are using refreshToken feature
              // Redirect or do-something
            }else {
              //Throw error
            }

            localStorage.setItem('prescriptionquest-authorization-token', res.headers.authorization);
          }
          setLoading(false);
          if (res.data.role === "pharmacy") {
            history.push('/accounts/pharmacies/dashboard');
          } else if (res.data.role === "patient") {
            history.push('/accounts/patients/dashboard');
          } else if (res.data.role === "staff") {
            history.push('/accounts/staffs/dashboard');
          }

        })
        .catch((error) => {
          console.log(error);
          setErrors("Login failed. Please correct email address and password.")
          setLoading(false);
        })
  }

  useEffect(() => {
    document.body.classList.add("account-page");

    return () => document.body.classList.remove("account-page");
  }, []);

  return (
    <>
      <Header {...props} />

      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar-two">
          <div className="container">
            <div className="row align-items-center inner-banner">
              <div className="col-md-12 col-12 text-center">
                <h2 className="breadcrumb-title">Login</h2>
                {/*<nav aria-label="breadcrumb" className="page-breadcrumb">*/}
                {/*  <ol className="breadcrumb">*/}
                {/*    <li className="breadcrumb-item">*/}
                {/*      <Link to="/">Home</Link>*/}
                {/*    </li>*/}
                {/*    <li className="breadcrumb-item" aria-current="page">*/}
                {/*      Dashboard*/}
                {/*    </li>*/}
                {/*  </ol>*/}
                {/*</nav>*/}
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}

        {/* Page Content */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {/* Login Tab Content */}
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    {/*<div className="col-md-7 col-lg-6 login-left">*/}
                    {/*  <img*/}
                    {/*    src={loginBanner}*/}
                    {/*    className="img-fluid"*/}
                    {/*    alt="PrescriptionsQuest Login"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h3>
                          Login <span>PrescriptionsQuest</span>
                        </h3>
                      </div>
                      <Form
                          layout={'horizontal'}
                          form={form}
                          name="login"
                          onFinish={onSubmit}
                          style={{ maxWidth: 600 }}
                      >
                        <div className="form-group">
                          <Form.Item name="email" rules={[{ required: true, message: "Email address is required" }]}
                          >
                            <Input
                                type="email"
                                size={"large"}
                                // prefix={<UserOutlined className="site-form-item-icon" />}
                                // className="form-control"
                                placeholder="Email Address"
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item name="password" rules={[{ required: true, message: "Password is required" }]}
                          >
                            <Input
                                size={"large"}
                                type="password"
                                // className="form-control"
                                placeholder="Password"
                            />
                          </Form.Item>
                        </div>
                        <div className="text-end">
                          <Link
                              className="forgot-link"
                              to="/accounts/forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        {errors &&
                          <Alert
                              message="Error"
                              description={errors}
                              type="error"
                              showIcon
                              className="mb-3"
                          />
                        }
                        <Button
                            size={"large"}
                            type="primary" htmlType="submit" className="w-100 login-btn"
                            icon={loading ? <LoadingOutlined /> : null}>
                          Login
                        </Button>
                        <hr/>
                        <Button
                            size={"large"}
                            type="default" htmlType="submit" className="w-100 login-btn"
                            onClick={() => history.push('/register')}
                            disabled={loading}>
                          Signup
                        </Button>
                      </Form>
                    </div>
                  </div>
                </div>
                {/* /Login Tab Content */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </>

      <Footer {...props} />
    </>
  );
};

export default LoginContainer;
